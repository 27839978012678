import query from 'query-string';

export const EMBED_PARENT_ORIGIN =
  window.location !== window.parent.location
    ? new URL(document.referrer).origin
    : 'https://preview.threekit.com';

export const APP_ORIGIN = window.location.origin.includes('localhost')
  ? 'http://localhost:5000'
  : window.location.origin;

export const APP_ENDPOINT = `${APP_ORIGIN}/api`;

const queryObj = query.parse(window.location.search);

export const APP_ID = queryObj.appid;
export const ORG_ID = queryObj.orgId;
export const TOKEN = queryObj.token;
export const PRODUCT_TYPE = queryObj.product || 'bag';
export const APP_TYPE = queryObj.appType || 'catalogUpdate';

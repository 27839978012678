import React, { Component } from 'react';
import Modal from 'react-modal';
import '../App.css';

import {
  EMBED_PARENT_ORIGIN,
  APP_ENDPOINT,
  APP_ID,
  ORG_ID,
  TOKEN,
  PRODUCT_TYPE,
} from '../constants';


const appMeta = {
  orgId: ORG_ID,
  appid: APP_ID,
  cookie: TOKEN,
  threekitOrigin: EMBED_PARENT_ORIGIN,
};

function CatalogItemStatus({ id, name }) {
  return <li>{`Name: ${name}${id ? `, id: ${id}` : ''}`}</li>;
}

function CatalogItemList({ title, list }) {
  return (
    <div>
      <h2>{title}</h2>
      <ul>{list.map((status) => CatalogItemStatus(status))}</ul>
    </div>
  );
}


const defaultCatalogUpdateStatus = {
  createdCatalogItems: [],
  updatedCatalogItems: [],
  deletedCatalogItems: [],
};
 



class MasterfileUpload extends Component {
  state = {
    isModalOpen: false,
    creatingCatalogItems: false, 
    catalogUpdateStatus: defaultCatalogUpdateStatus
  }


  onClick = () => {
    this.setState({creatingCatalogItems:true});

    fetch(`${APP_ENDPOINT}/catalog/status`, {
      method: 'POST',
      body: JSON.stringify({ appMeta, productType: PRODUCT_TYPE }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status && json.status === 500) {
          window.alert(json.message);
          this.setState({creatingCatalogItems:false});
        }
        this.setState({catalogUpdateStatus:json});
        this.setState({isModalOpen:true});
      });
  };

  onProcess = () => {
    this.setState({isModalOpen:false});

    fetch(`${APP_ENDPOINT}/catalog/create`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta,
        data: this.state.catalogUpdateStatus,
        productType: PRODUCT_TYPE,
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status && json.status !== 200) {
          window.alert(json.message);
        }
        this.setState({creatingCatalogItems:false});
        this.setState({catalogUpdateStatus:defaultCatalogUpdateStatus});
      });
  };



  


  render(){
    const {isModalOpen,
      creatingCatalogItems, 
      catalogUpdateStatus} = this.state;
    return (
      <div className="App">
        <h1>Dior Admin App</h1>
        <p>
          This tool is used to create/update catalog items based on the master
          file table. Please read the following description and make sure the
          table data is up to data before you running the script.
        </p>
        <ul>
          <li>
            <p>
              The app will synchronize the existing catalog items with the current
              master file table data, which means it may create new, update or
              delete exist catalog items.
            </p>
          </li>
          <li>
            <p>
              When running the app, a popup window with the update informtaion
              will be present, please pay attention to any delete catalog items,
              as the rendering are using the catalog id instead of name
              internally, so if an catalog item are removed by accident, creating
              a new one with the same name does not work.
            </p>
          </li>
          <li>
            <p>
              In case if a catalog item got deleted by accident, you could restore
              it from the trash folder within the org.
            </p>
          </li>
          <li>
            <p>
              When update a catalog item, it will keep its linked assets (if
              exist). In addition, it will keep any additional keywords and
              metadata added by user that start and end with _ in the name, for
              example, _Custom Metadata_. However, any other keywords or metadata
              that does not match the above naming convention and it is not result
              of the data table, will be ignored and removed.
            </p>
          </li>
        </ul>

        <button
          disabled={!EMBED_PARENT_ORIGIN || creatingCatalogItems}
          onClick={this.onClick}
        >
          Create catalog items
        </button>
        <Modal isOpen={isModalOpen} contentLabel="Status">
          <h2>
            Please check the list below and confirm that you want to procress!
          </h2>
          {!!catalogUpdateStatus.createdCatalogItems.length && (
            <CatalogItemList
              title="Catalog Items to be created!"
              list={catalogUpdateStatus.createdCatalogItems}
            />
          )}
          {!!catalogUpdateStatus.updatedCatalogItems.length && (
            <CatalogItemList
              title="Catalog Items to be updated!"
              list={catalogUpdateStatus.updatedCatalogItems}
            />
          )}
          {!!catalogUpdateStatus.deletedCatalogItems.length && (
            <CatalogItemList
              title="Catalog Items to be delete!"
              list={catalogUpdateStatus.deletedCatalogItems}
            />
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button
              onClick={() => {
                this.setState({isModalOpen:false});
                this.setState({creatingCatalogItems:false});
              }}
            >
              Cancel
            </button>
            <button onClick={this.onProcess}>Confirm</button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default MasterfileUpload;

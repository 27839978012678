import React, { Component } from 'react';
import Modal from 'react-modal';
import '../App.css';

import {
  EMBED_PARENT_ORIGIN,
  APP_ENDPOINT,
  APP_ID,
  ORG_ID,
  TOKEN,
} from '../constants';


const appMeta = {
  orgId: ORG_ID,
  appid: APP_ID,
  cookie: TOKEN,
  threekitOrigin: EMBED_PARENT_ORIGIN,
};

function CatalogItemStatus({ id, name }) {
  return <li>{`Name: ${name}${id ? `, id: ${id}` : ''}`}</li>;
}

function CatalogItemList({ title, list }) {
  return (
    <div>
      <h2>{title}</h2>
      <ul>{list.map((status) => CatalogItemStatus(status))}</ul>
    </div>
  );
}

const defaultAssetMetadataStatus = {
  AssetsToUpdate: []
};


class AssetMetadataUpdate extends Component {
  state = {
    isModalOpen: false,
    updatingAssetMetadata:false,
    assetUpdateStatus: defaultAssetMetadataStatus
  }


  onClickAssetMetaData = () => {
    this.setState({updatingAssetMetadata:true});
    const tagInput = document.getElementById('tagInput').value

    fetch(`${APP_ENDPOINT}/assetMetadata/query`, {
      method: 'POST',
      body: JSON.stringify({ appMeta, tag: tagInput }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status && json.status === 500) {
          window.alert(json.message);
          this.setState({updatingAssetMetadata:false});
        }
        this.setState({assetUpdateStatus:json});
        this.setState({isModalOpen:true});
      });
  };
  onProcessAssetMetaData = () => {
    this.setState({isModalOpen:false});

    const tagInput = document.getElementById('tagInput').value
    fetch(`${APP_ENDPOINT}/assetMetadata/update`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta,
        data: this.state.assetUpdateStatus,
        tag: tagInput
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status && json.status !== 200) {
          window.alert(json.message);
        }
        this.setState({updatingAssetMetadata:false});
        this.setState({assetUpdateStatus:defaultAssetMetadataStatus});

      });
  };

  render() {
    const { isModalOpen, updatingAssetMetadata, assetUpdateStatus } = this.state;

    return (
      <div className="App">
        <h1>Dior Asset Metadata Update App</h1>
        <p>
          Enter a tag name below to update all assets with the specified tag:
        </p>
        <p>
          <input id='tagInput' className='tagInput'></input>
        </p>
        <button
          disabled={!EMBED_PARENT_ORIGIN || updatingAssetMetadata}
          onClick={this.onClickAssetMetaData}
        >
          Update Asset Metadata
        </button>

        <Modal isOpen={isModalOpen} contentLabel="Status">
          <h2>
            Please check the list below and confirm that you want to process!
          </h2>
          {!!assetUpdateStatus.AssetsToUpdate.length && (
            <CatalogItemList
              title="Assets to be updated!"
              list={assetUpdateStatus.AssetsToUpdate}
            />
          )}

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button
              onClick={() => {
                this.setState({isModalOpen:false});
                this.setState({updatingAssetMetadata:false});
              }}
            >
              Cancel
            </button>
            <button onClick={this.onProcessAssetMetaData}>Confirm</button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AssetMetadataUpdate;

import React, { Component, } from 'react';
import Select from 'react-select';
import '../App.css';
import {   message } from 'antd';


import {
  EMBED_PARENT_ORIGIN,
  APP_ENDPOINT,
  APP_ID,
  ORG_ID,
  TOKEN,
} from '../constants';


const appMeta = {
  orgId: ORG_ID,
  appid: APP_ID,
  cookie: TOKEN,
  threekitOrigin: EMBED_PARENT_ORIGIN,
};

function CompositeJobStatus(jobStatus) {
  if (jobStatus.jobID.totalTasks === jobStatus.jobID.successTasks) {
    return (
      <div>
        <h3>{`Composite Job Status: Ready to Download ${jobStatus.jobID.totalTasks - 1} Image(s)`}</h3>
      </div>
    );
  } else if (jobStatus.jobID.invalidJobId) {
    return (
      <div>
        <h3>{`Invalid Job ID`}</h3>
      </div>
    );
  } else {
    return (
      <div>
        <h3>{`Composite Job Status: Pending (${jobStatus.jobID.successTasks}/${jobStatus.jobID.totalTasks})`}</h3>
      </div>
    );
  }
}
const getProgress = (progress, idx, newData) => {
  const newProgress = [...progress];
  newProgress.splice(idx, 1, Object.assign(progress[idx], newData));
  return newProgress;
};


const RotationY_Horizontal = [{ value: 0, label: '0' },
{ value: 15, label: '15' },
{ value: 30, label: '30' },
{ value: 45, label: '45' },
{ value: 60, label: '60' },
{ value: 75, label: '75' },
{ value: 90, label: '90' },
{ value: 105, label: '105' },
{ value: 120, label: '120' },
{ value: 135, label: '135' },
{ value: 150, label: '150' },
{ value: 165, label: '165' },
{ value: 180, label: '180' },
{ value: 195, label: '195' },
{ value: 210, label: '210' },
{ value: 225, label: '225' },
{ value: 240, label: '240' },
{ value: 255, label: '255' },
{ value: 270, label: '270' },
{ value: 285, label: '285' },
{ value: 300, label: '300' },
{ value: 315, label: '315' },
{ value: 330, label: '330' },
{ value: 345, label: '345' }
];

const RotationX_Vertical = [{ value: 0, label: '0' }, { value: -30, label: '-30' }]

var selectedProduct2 = "";

var selectedSkin2 = [];
var finishList2 = [];
var colorList2 = [];
var selectedFinish2 = [];
var selectedColor2 = [];

const defaultProducts = [];


class RenderDownload extends Component {
  state = {
    productsSet: false,
    productList: defaultProducts,
    productOptions: defaultProducts,
    selectedProduct: "",
    skinList: defaultProducts,
    skinOptions: defaultProducts,
    selectedSkin: defaultProducts,
    finishList: defaultProducts,
    finishOptions: defaultProducts,
    colorOptions: defaultProducts,
    jewelleryList: defaultProducts,
    jewelleryOptions: defaultProducts,
    covJewelleryOptions: defaultProducts,
    selectedfinish: defaultProducts,
    selectedcolor: defaultProducts,
    selectedJewellery: defaultProducts,
    selectedCovJewellery: defaultProducts,
    selectedRotationY: [{ value: 0 }],
    selectedRotationX: [{ value: 0 }],


    downloadJobID: '',
    downloadJobIDStatus: '',

    loading: false,
    jobID: '',
    progress: [],
    totalCount: 0,
    curIdx: 0,

    compositeProgress: [],
    compositeCount: 0,
    curCompositeIdx: 0

  }


  //Get Configuration Options
  getProducts = () => {
    fetch(`${APP_ENDPOINT}/catalog/getProductList`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status && json.status !== 200) {
          window.alert(json.message);
        }
        this.setState({ productsSet: true });
        this.setState({ productList: json });
        const ProdOptions = json.map(({ product }) => ({ value: product.id, label: product.name }));
        this.setState({ productOptions: ProdOptions });
      });
  }
  getSkin = () => {
    const { productList } = this.state;
    if (productList.find(item => { return item.product.id === selectedProduct2 })) {
      const prodAttributes = productList.find(item => { return item.product.id === selectedProduct2 }).product.attributes;
      const tags = prodAttributes.find(item => { return item.name === 'Skin' }).values;
      fetch(`${APP_ENDPOINT}/catalog/getAttributeValues`, {
        method: 'POST',
        body: JSON.stringify({
          appMeta,
          tags
        }),
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status && json.status !== 200) {
            window.alert(json.message);
          }
          const SkinOptions = json.map(({ product }) => ({ value: product.id, label: product.name }));
          this.setState({ skinOptions: SkinOptions });
          this.setState({ skinList: json });
        });
    }
  }
  getFinish = () => {
    const { productList } = this.state;
    if (productList.find(item => { return item.product.id === selectedProduct2 })) {
      const prodAttributes = productList.find(item => { return item.product.id === selectedProduct2 }).product.attributes;
      const finishTags = prodAttributes.find(item => { return item.name === 'Finishing' }).values;
      finishList2 = [];

      finishTags.forEach((curVal) => {
        const tags = curVal;
        fetch(`${APP_ENDPOINT}/catalog/getAttributeValues`, {
          method: 'POST',
          body: JSON.stringify({
            appMeta,
            tags
          }),
          headers: {
            'content-type': 'application/json',
          },
        })
          .then((res) => res.json())
          .then(
            (json) => {
              if (json.status && json.status !== 200) {
                window.alert(json.message);
              }
              const tagFinishings = json.map(({ product }) => ({ value: product.id, label: product.name }));
              const curOptions = tagFinishings.reduce((curFinishings, option) => {
                const colSplit = option.label.split("_");
                if (!selectedSkin2 || selectedSkin2.length === 0 || selectedSkin2.find((skin) => skin.label === colSplit[1])) {
                  return curFinishings.concat(option);
                } else { return curFinishings; }
              }, []);
              if (curOptions && curOptions.length !== 0) {
                finishList2 = finishList2.concat(curOptions);
              }
              this.setState({ finishList: json });
              this.setState({ finishOptions: finishList2 });
            }
          )
      })

    }
  }
  getColor = () => {
    const { productList } = this.state;
    if (productList.find(item => { return item.product.id === selectedProduct2 })) {
      const prodAttributes = productList.find(item => { return item.product.id === selectedProduct2 }).product.attributes;
      const colorTags = prodAttributes.find(item => { return item.name === 'Color' }).values;
      colorList2 = [];

      colorTags.forEach((curVal) => {
        const tags = curVal;
        fetch(`${APP_ENDPOINT}/catalog/getAttributeValues`, {
          method: 'POST',
          body: JSON.stringify({
            appMeta,
            tags
          }),
          headers: {
            'content-type': 'application/json',
          },
        })
          .then((res) => res.json())
          .then(
            (json) => {
              if (json.status && json.status !== 200) {
                window.alert(json.message);
              }
              const tagColors = json.map(({ product }) => ({ value: product.id, label: product.name }));

              const curOptions = tagColors.reduce((curColors, option) => {
                const colSplit = option.label.split("_");
                if ((!selectedSkin2 || selectedSkin2.length === 0 || selectedSkin2.find((skin) => skin.label === colSplit[2])) &&
                  (!selectedFinish2 || selectedFinish2.length === 0 || selectedFinish2.find((finish) => finish.label.includes(`${colSplit[1]}_${colSplit[2]}`)))) {
                  return curColors.concat(option);
                } else {
                  return curColors;
                }
              }, []);
              if (curOptions && curOptions.length !== 0) {
                colorList2 = colorList2.concat(curOptions);
              }
              this.setState({ colorOptions: colorList2 });
            }
          )
      })

    }
  }
  getJewellery = () => {
    const { productList } = this.state;
    if (productList.find(item => { return item.product.id === selectedProduct2 })) {
      const prodAttributes = productList.find(item => { return item.product.id === selectedProduct2 }).product.attributes;

      if (prodAttributes && prodAttributes.find(item => { return item.name === 'Jewellery' })) {
        const tags = prodAttributes.find(item => { return item.name === 'Jewellery' }).values;
        fetch(`${APP_ENDPOINT}/catalog/getAttributeValues`, {
          method: 'POST',
          body: JSON.stringify({
            appMeta,
            tags
          }),
          headers: {
            'content-type': 'application/json',
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status && json.status !== 200) {
              window.alert(json.message);
            }

            const thisJewelOptions = json.map(({ product }) => ({ value: product.id, label: product.name }));

            this.setState({ jewelleryOptions: thisJewelOptions });
            this.setState({ jewelleryList: json });
          });
      } else { this.setState({ jewelleryOptions: [] }); }
    }
  }
  getCoveredJewellery = () => {
    const { productList } = this.state;
    if (productList.find(item => { return item.product.id === selectedProduct2 })) {
      const prodAttributes = productList.find(item => { return item.product.id === selectedProduct2 }).product.attributes;
      if (prodAttributes && prodAttributes.find(item => { return item.name === 'Covered Jewellery' })) {

        const coveredJewellery = prodAttributes.find(item => { return item.name === 'Covered Jewellery' });
        if (coveredJewellery) {
          const coveredJewelleryVals = coveredJewellery.values;
          const covJewelOptions = coveredJewelleryVals.map((val) => ({ value: val, label: val }));

          this.setState({ covJewelleryOptions: covJewelOptions });
        }
      } else { this.setState({ covJewelleryOptions: [] }) }
    }
  }

  //Process Configuration Changes
  onProductChange = (event) => {
    this.setState({ selectedProduct: event.value });
    selectedProduct2 = event.value;

    this.getSkin();
    this.getFinish();
    this.getColor();
    this.getJewellery();
    this.getCoveredJewellery();


    selectedSkin2 = [];
    selectedFinish2 = [];
    selectedColor2 = [];
    this.setState({ selectedSkin: [] });
    this.setState({ selectedfinish: [] });
    this.setState({ selectedcolor: [] });
    this.setState({ selectedJewellery: [] });
    this.setState({ selectedCovJewellery: [] });
  }
  onSkinChange = (event) => {
    this.setState({ selectedSkin: event });
    selectedSkin2 = event;
    this.getFinish();
    this.getColor();

    selectedFinish2 = [];
    selectedColor2 = [];
    this.setState({ selectedfinish: [] });
    this.setState({ selectedcolor: [] });
  }
  onFinishChange = (event) => {
    this.setState({ selectedfinish: event });
    selectedFinish2 = event;
    this.getColor();

    selectedColor2 = [];
    this.setState({ selectedcolor: [] });
  }
  onColorChange = (event) => {
    this.setState({ selectedcolor: event });
    selectedColor2 = event;
  }
  onJewelleryChange = (event) => {
    this.setState({ selectedJewellery: event });
  }
  onCoveredJewelleryChange = (event) => {
    this.setState({ selectedCovJewellery: event });
  }
  onRotationYChange = (event) => {
    this.setState({ selectedRotationY: event });
  }
  onRotationXChange = (event) => {
    this.setState({ selectedRotationX: event });
  }

  getJobStatus = () => {
    var jobID = document.getElementById('jobID').value.trim();
    try {
      fetch(`${APP_ENDPOINT}/catalog/getJobStatus`, {
        method: 'POST',
        body: JSON.stringify({
          appMeta,
          jobID
        }),
        headers: { 'Content-Type': 'application/json' },
      }).then((res) => res.json())
        .then((json) => {
          if (json.status && json.status === 500) {
            window.alert(json.message);
          }
          if (json.tasks) {
            const totalTasks = json.tasks.length;
            const successTasks = json.tasks.filter(value => value.status === 'success').length;;
            const jobStatus = {
              totalTasks,
              successTasks,
              invalidJobId: false
            };
            this.setState({ downloadJobIDStatus: jobStatus });
          } else {
            const jobStatus = {
              totalTasks: 0,
              successTasks: -1,
              invalidJobId: true
            };
            this.setState({ downloadJobIDStatus: jobStatus });
          }
        });
    } catch (e) {
      const jobStatus = {
        totalTasks: 0,
        successTasks: -1
      };
      this.setState({ downloadJobIDStatus: jobStatus });
    }

  }
  downloadImageButton = async () => {

    const { downloadJobID, loading } = this.state;

    var jobID = document.getElementById('jobID').value.trim();
    console.log(jobID);
    if (!downloadJobID || loading) return;
    this.setState({ loading: true });

    let response, res;
    try {
      response = await fetch(`${APP_ENDPOINT}/catalog/getJobSize`, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({
          appMeta,
          jobID
        }),
      });
      res = await response.json();
    } catch (e) {
      return message.error('Error initial download job:', e.toString());
    }

    console.log(res);
    if (response.status !== 200 || res.error) {
      return message.error(res.error);
    }

    if (res.running) {
      this.setState({ loading: false });

      return message.info(
        `Server currently process job ${res.jobId}, ${res.processed}/${res.total
        } processed! Please wait and try later!`
      );
    }

    const groupCount = 10;
    const totalCount = res.count;
    this.setState({ totalCount: totalCount });

    let groupIdx = 0;
    const progress = [];
    while (groupCount * groupIdx < totalCount) {
      const startFileIdx = groupCount * groupIdx;
      const groupTotalCount = Math.min(groupCount, totalCount - startFileIdx);
      const endFileIdx = startFileIdx + groupTotalCount;
      progress[groupIdx] = {
        status: 'awaiting',
        message: `Files ${startFileIdx + 1} ~ ${endFileIdx}`,
        data: res.tasks.slice(startFileIdx, endFileIdx),
        processed: 0,
      };
      groupIdx += 1;
    }

    this.setState({ progress });
    console.log(progress);

    for (let processedIdx = 0; processedIdx < progress.length; ++processedIdx) {
      console.log(processedIdx);
      await new Promise(async (resolve, reject) => {
        var newProgressState = { status: 'procressing' };
        const resolveWithError = () => {
          newProgressState.status = 'error';
          this.setState({
            progress: getProgress(progress, processedIdx, newProgressState),
          });
          resolve();
        };
        this.setState({
          progress: getProgress(progress, processedIdx, newProgressState),

          curIdx: processedIdx
        });

        //retrieve Job files          
        const retrieveJobRes = await fetch(`${APP_ENDPOINT}/catalog/retrieveJobFiles`, {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/json' }),
          body: JSON.stringify({ appMeta, jobID, data: progress[processedIdx].data }),
        }).then((res) => res.json());

        console.log(retrieveJobRes);
        if (retrieveJobRes.error) {
          return resolveWithError();
        }
        newProgressState = { status: 'complete' };
        this.setState({
          progress: getProgress(progress, processedIdx, newProgressState),
        });

        resolve();
      });
    }


    //retrieve Job files          
    fetch(`${APP_ENDPOINT}/catalog/downloadZip`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta,
        jobID
      }),
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
      if (res.status === 200) {
        return res.blob();
      }
      return null;
    }).then(blob => {
      try {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = downloadJobID + ".zip";

        anchor.click();
      } catch (e) {
        console.log(e);
      }
    })

    /*fetch(`${APP_ENDPOINT}/catalog/downloadCompositeImages`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta,
        jobID
      }),
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
      if (res.status === 200) {
        return res.blob();
      }
      return null;
    }).then(blob => {
      try {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = downloadJobID + ".zip";

        anchor.click();
      } catch (e) {
        console.log(e);
      }
    });
  */
    this.setState({ loading: false });

  }


  getCompositePayload = () => {
    const { productList, selectedRotationX, selectedRotationY, selectedJewellery, selectedCovJewellery } = this.state;

    const product = productList.find(item => { return item.product.id === selectedProduct2 }).product;

    const compositePayload = {
      id: product.id,
      name: product.name,
      attributes: []
    }

    const attributes = [];
    var rotVal = 0;
    if (product.attributes.find(item => { return item.name === 'RotationY_Horizontal' })) {

      const rotYAttID = product.attributes.find(item => { return item.name === 'RotationY_Horizontal' }).id;
      rotVal = 0;
      if (selectedRotationY.value) { rotVal = selectedRotationY.value; }
      attributes.push({
        id: rotYAttID,
        name: "RotationY_Horizontal",
        values: [rotVal]
      });
    }
    if (product.attributes.find(item => { return item.name === 'RotationX_Vertical' })) {

      const rotXAttID = product.attributes.find(item => { return item.name === 'RotationX_Vertical' }).id;
      rotVal = 0;
      if (selectedRotationX.value) { rotVal = selectedRotationX.value; }
      attributes.push({
        id: rotXAttID,
        name: "RotationX_Vertical",
        values: [rotVal]
      });
    }
    if (product.attributes.find(item => { return item.name === 'Color' })) {

      const colorAttId = product.attributes.find(item => { return item.name === 'Color' }).id;
      attributes.push({
        id: colorAttId,
        name: "Color",
        values: selectedColor2
      });
    }
    if (product.attributes.find(item => { return item.name === 'Jewellery' })) {
      const jewelleryAttId = product.attributes.find(item => { return item.name === 'Jewellery' }).id;
      attributes.push({
        id: jewelleryAttId,
        name: "Jewellery",
        values: selectedJewellery
      });
    }
    if (product.attributes.find(item => { return item.name === 'Covered Jewellery' })) {
      const covJewelleryAttId = product.attributes.find(item => { return item.name === 'Covered Jewellery' }).id;
      attributes.push({
        id: covJewelleryAttId,
        name: "Covered Jewellery",
        values: selectedCovJewellery.map((item) => item.value)
      });
    }
    compositePayload.attributes = attributes;
    return compositePayload;
    // var tempAssets = selectedcolor.map()

  }
  generateCompositeJob = async () => {

    this.setState({ loading: true });

    const compositePayload = this.getCompositePayload();


    let response, res;
    try {
      response = await fetch(`${APP_ENDPOINT}/catalog/startCompositeJob`, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({
          appMeta,
          compositePayload
        }),
      });
      res = await response.json();
    } catch (e) {
      return message.error('Error initial download job:', e.toString());
    }

    if (response.status !== 200 || res.error) {
      return message.error(res.error);
    }

    
    if (res.running) {
      this.setState({ loading: false });

      return message.info(
        `Server currently process job ${res.jobName}, ${res.processed}/${res.total
        } processed! Please wait and try later!`
      );
    }


    const availableConfigurations = res.availableConfigurations;
    const jobName = res.jobName;


    const groupCount = 5;
    const compositeCount = availableConfigurations.length;
    this.setState({ compositeCount: compositeCount });

    let groupIdx = 0;
    const compositeProgress = [];
    while (groupCount * groupIdx < compositeCount) {
      const startFileIdx = groupCount * groupIdx;
      const groupTotalCount = Math.min(groupCount, compositeCount - startFileIdx);
      const endFileIdx = startFileIdx + groupTotalCount;
      compositeProgress[groupIdx] = {
        status: 'awaiting',
        message: `Files ${startFileIdx + 1} ~ ${endFileIdx}`,
        data: availableConfigurations.slice(startFileIdx, endFileIdx),
        processed: 0,
      };
      groupIdx += 1;
    }

    this.setState({ compositeProgress });
    console.log(compositeProgress);
    
    for (let processedIdx = 0; processedIdx < compositeProgress.length; ++processedIdx) {
      console.log(processedIdx);
      await new Promise(async (resolve, reject) => {
        var newProgressState = { status: 'procressing' };
        const resolveWithError = () => {
          newProgressState.status = 'error';
          this.setState({
            compositeProgress: getProgress(compositeProgress, processedIdx, newProgressState),
          });
          resolve();
        };
        this.setState({
          compositeProgress: getProgress(compositeProgress, processedIdx, newProgressState),

          curCompositeIdx: processedIdx
        });
        //retrieve Job files          
        const retrieveJobRes = await fetch(`${APP_ENDPOINT}/catalog/generateCompositeJob`, {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/json' }),
          body: JSON.stringify({ appMeta, jobName, data: compositeProgress[processedIdx].data })
        }).then((res) => res.json());

        console.log(retrieveJobRes);
        if (retrieveJobRes.error) {
          return resolveWithError();
        }
        newProgressState = { status: 'complete' };
        this.setState({
          compositeProgress: getProgress(compositeProgress, processedIdx, newProgressState),
        });

        resolve();
      });
    }

    this.setState({ compositeProgress });
    console.log(compositeProgress);

    fetch(`${APP_ENDPOINT}/catalog/downloadCompositeZip`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta,
        jobName
      }),
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
      if (res.status === 200) {
        return res.blob();
      }
      return null;
    }).then(blob => {
      try {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = jobName + ".zip";

        anchor.click();
      } catch (e) {
        console.log(e);
      }
    })
   /* fetch(`${APP_ENDPOINT}/catalog/generateCompositeJob`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta,
        compositePayload
      }),
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
      if (res.status === 200) {
        return res.blob();
      }
      return null;
    }).then(blob => {
      try {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = "renders.zip";

        anchor.click();
      } catch (e) {
        console.log(e);
      }
    });
*/
    this.setState({ loading: false });
  }
 

  //Calculate number of composite images to be created
  expectedRenders = () => {
    const { jewelleryOptions, covJewelleryOptions, selectedJewellery, selectedCovJewellery } = this.state;

    //Calculate the number of renders to be created

    //always include selected colors
    var expectedRenders = selectedColor2.length;

    //if product has jewellery options, then multiply by the number selected
    if (jewelleryOptions.length > 0) {
      expectedRenders = expectedRenders * selectedJewellery.length;

      //if product has covered jewellery option, then multiply by the number selected, except don't include "Yes" option for Strass Charm
      if (covJewelleryOptions.length > 0) {
        var numCovJewelSelected = 0;
        if (selectedCovJewellery.find((item) => item.value === "No")) {
          numCovJewelSelected = expectedRenders;
        }
        if (selectedCovJewellery.find((item) => item.value === "Yes")) {

          const nonStrassCharmCount = selectedJewellery.reduce((accumulator, curItem) => {
            if (!curItem.label.includes("Strass Charm")) {
              return accumulator + 1;
            } else { return accumulator; }
          }, 0);
          numCovJewelSelected = numCovJewelSelected + selectedColor2.length * nonStrassCharmCount;
        }
        expectedRenders = numCovJewelSelected;
      }
    }
    return expectedRenders;
  }

  resetJobStatus = async () => {
    try {
      await fetch(`${APP_ENDPOINT}/resetServerStatus`, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({
          appMeta
        }),
      });
    } catch (e) {
      return message.error('Error resetting  status:', e.toString());
    }
    return message.info(`Server state reset`);
  }
  render() {
    const { productsSet, productOptions, skinOptions, finishOptions, colorOptions,
      jewelleryOptions, covJewelleryOptions, selectedJewellery, selectedCovJewellery,
      downloadJobIDStatus, loading, progress, curIdx, totalCount,curCompositeIdx,compositeProgress,compositeCount } = this.state;

    if (!productsSet) {
      this.getProducts();
    }
    return (
      <div className="App">
        <h1>Dior Render Download App</h1>
        <div className="GenerateComposite">
          <h2>Generate Composites</h2>
          <p>Product:
            <Select onChange={this.onProductChange} options={productOptions} />
          </p>
          <p>
            RotationY_Horizontal:
            <Select defaultValue={RotationY_Horizontal[0]} onChange={this.onRotationYChange} options={RotationY_Horizontal} />
            RotationX_Vertical:
            <Select defaultValue={RotationX_Vertical[0]} onChange={this.onRotationXChange} options={RotationX_Vertical} />
          </p>
          <p>
            Skin:
            <Select onChange={this.onSkinChange} isMulti={true} options={skinOptions} value={selectedSkin2} />

            Finish:
            <Select onChange={this.onFinishChange} isMulti={true} options={finishOptions} value={selectedFinish2} />
            Color:
            <Select onChange={this.onColorChange} isMulti={true} options={colorOptions} value={selectedColor2} />
          </p>
          <p>
            {jewelleryOptions.length > 0 && ("Jewellery: ")}
            {jewelleryOptions.length > 0  && (
              <Select onChange={this.onJewelleryChange} isMulti={true} options={jewelleryOptions} value={selectedJewellery} />
            )}
            {(covJewelleryOptions.length > 0 ) && ("Covered Jewellery:")}
            {covJewelleryOptions.length > 0  && (

              <Select onChange={this.onCoveredJewelleryChange} isMulti={true} options={covJewelleryOptions} value={selectedCovJewellery} />
            )}
          </p>
          <p>
            Expected Renders to be generated: {this.expectedRenders()}
          </p><p>
            <button disabled={loading || this.expectedRenders() === 0} onClick={this.generateCompositeJob}>Generate Composite Job</button>

            {!!compositeProgress.length && (
            <p>{`Status: ${loading === true
              ? `Processing: ${Math.round((100*(curCompositeIdx*5)/compositeCount)*10)/10}%`
              : 'Complete'
              }`}</p>
          )}
          </p>
        </div>

        <div className="GenerateComposite">
          <h2>Download Renders from Job</h2>
          <h3>Job ID: <input id='jobID'
            onChange={(e) => this.setState({ downloadJobID: e.target.value })} /></h3>
          <button onClick={this.getJobStatus}>Check Job Status</button>
          {!!downloadJobIDStatus && (
            <CompositeJobStatus jobID={downloadJobIDStatus} />
          )}
          {!!downloadJobIDStatus && (
            <button disabled={loading} onClick={this.downloadImageButton}>Download Images</button>
          )}

          {!!progress.length && (
            <p>{`Status: ${loading === true
              ? `Processing: ${curIdx * 10} / ${totalCount}`
              : 'Complete'
              }`}</p>
          )}

        </div>
        <div className="GenerateComposite">
          <button onClick={this.resetJobStatus}>Reset Server State</button>
        </div>

      </div>
    );

  }
}

export default RenderDownload;
